import { defineStore } from 'pinia';

import { useChatbots } from './chatbots.store';
import { useProfile } from './profile.store';

interface Header {
  i18Title: string;
  withBack: boolean;
  editable: boolean;
  editableTitle: string;
}

const getEditableTitle = (routeName: string) => {
  const chatbotsStore = useChatbots();
  const editableTitles = {
    ['ChatBotsEditor']: chatbotsStore.getCurrent.name,
  };

  // @ts-ignore
  return editableTitles[routeName];
};

export const useHeader = defineStore('header', {
  actions: {
    async updateEditableTitle(payload: string) {
      const { name } = this.router.currentRoute.value;
      const chatbotsStore = useChatbots();

      const methods = {
        ['ChatBotsEditor']: chatbotsStore.putchBot,
      };
      const idBots = {
        ['ChatBotsEditor']: chatbotsStore.botData.id,
      }

      // @ts-ignore
      await methods[name](idBots[name], { name: payload });

     
    },
  },

  getters: {
    routeMeta(): Header {
      const { meta, name: routeName } = this.router.currentRoute.value;
      const chatbotsStore = useChatbots();
      const profileStore = useProfile();

      const i18Title = meta.pageTitle || '';
      const editable = meta.canEditTitle || false;
      let withBack = meta.withBackRedirect || false;
      let editableTitle = '';

      if (routeName === 'tariffs' && profileStore.isFirstEntry) {
        withBack = false;
      }
      if (routeName === 'ChatBotsEditor' && chatbotsStore.botData.name) {
        editableTitle = chatbotsStore.botData.name;
      }

      return {
        i18Title,
        withBack,
        editable,
        editableTitle,
      };
    },
  },
});
