import { Channel } from '~/entities/channel';
import type { Graph, IGraphState } from 'baklavajs';
import { ChatBotStartType } from '~/stores/chatbots.store';

export enum ChatbotsStatus {
  active = 'active',
  ready = 'ready',
  draft = 'draft',
  test = 'test',
}

export enum BotStatus {
  Draft = 'DRAFT',
  Active = 'ACTIVE',
  Disabled = 'DISABLED',
}

export enum BotTypes {
  telegram = 'TELEGRAM',
  waba = 'WHATSAPP'
}

export enum ChatBotStepType {
  // шаг-условие
  step = 'step',
  // действие
  action = 'action',
  // timeout step
  timeout = 'timeout',
  // start step
  start = 'start',
  // шаг отправка вебхука во вне
  webhook = "webhook",
  // шаг остановки бота
  end = 'end',
}

export interface BotRequestBody {
  type: string,
  status: string,
  name: string,
  channelId: string,
  schema: any
}

export interface Bot {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  userId: string,
  type: string,
  status: string,
  name: string,
  channelId: string,
  companyId: string | null,
  startType: ChatBotStartType | null,
  templateText: string | null,
  botSnapshots: botSchema[],
  channel?: Channel,
}

export interface botSchema {
  id: string,
  createdAt: Date,
  updatedAt: Date,
  botId: string,
  isCurrent: true,
  schema: IGraphState | unknown
}

export interface ChatbotRequestBody {
  name: string;
  priority: number;
  companyId: string;
  channelId: string;
}

export interface Chatbot extends Record<string, any> {
  id: string;
  createdAt: Date;
  updatedAt: Date;
  userId: string;
  status: ChatbotsStatus;
  name: string;
  priority: number;
  channelId: string;

  connections?: ChatbotConnection[];
  steps?: ChatbotStep[];
  channel?: Channel;
}

export interface ChatbotStep {
  id: string;
  chatBotId: string;
  createdAt: Date;
  updatedAt: Date;
  function: any;
  type: ChatBotStepType;
  coords: any;
  state: any;
}

export interface ChatbotConnection {
  id: string;
  chatBotId: string;
  createdAt: Date;
  updatedAt: Date;
  outputKey: string | null;
  stepFromId: string | null;
  stepToId: string | null;

  stepTo?: ChatbotStep;
  stepFrom?: ChatbotStep;
}
