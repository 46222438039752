<script setup lang="ts">
import { computed, ref } from 'vue';
import { useChannels } from '~/stores/channel.store';
import { useProfile } from '~/stores/profile.store';
import { useI18n } from 'vue-i18n';

const channelsStore = useChannels();
const profileStore = useProfile();
const { t } = useI18n();

/**
 * modal
 */
const modalAddChannelVisible = ref(false);
const modalAddChannelTypeWzVisible = ref(false);
const modalAddChannelTypeTgVisible = ref(false);

const showAddChannelModal = () => {
  modalAddChannelVisible.value = true;
};

const onAddChannelType = (type: 'wz' | 'tg') => {
  switch (type) {
    case 'wz':
      modalAddChannelTypeWzVisible.value = true;
      break;
    case 'tg':
      modalAddChannelTypeTgVisible.value = true;
      break;
  }
};

// Добавить канал Wz
const onAddChannelWz = async () => {
  await channelsStore.addWabaChannel();
  await profileStore.initProfile();
};

// Добавить канал Tg
const onAddChannelTg = async (token: string) => {
  const channel = await channelsStore.addTgChannel(token);

  if (channel) {
    await channelsStore.fetchChannels();
    modalAddChannelTypeTgVisible.value = false;
  }
};

const isShowAddButton = computed(() => profileStore.haveNoNegativeStatusChannel);
const buttonColor = computed<'primary' | 'secondary'>(() => {
  return profileStore.haveNoNegativeStatusChannel ? 'primary' : 'secondary';
});
const buttonText = computed<string>(() => {
  return profileStore.haveNoNegativeStatusChannel
    ? t('profile.channel.add')
    : t('profile.channel.addChannel');
});

// Текст в шапке блока
const content = computed(() =>
  profileStore.haveNoNegativeStatusChannel ? t('profile.channel.headerContentEmpty') : t('profile.channel.headerContentList'),
);
</script>

<template>
  <ProfileModalAddChannel v-model="modalAddChannelVisible" @select="onAddChannelType" />
  <ProfileModalAddChannelWz v-model="modalAddChannelTypeWzVisible" @back="showAddChannelModal" @add="onAddChannelWz" />
  <ProfileModalAddChannelTg v-model="modalAddChannelTypeTgVisible" @back="showAddChannelModal" @add="onAddChannelTg" />
  <div class="profile-channel">
    <div class="profile-channel__header">
      <div class="profile-channel__header-content">
        <h4 class="profile-channel__title">Каналы</h4>
        <span class="profile-channel__description" v-html="content"></span>
      </div>
      <div class="profile-channel__header-btn">
        <PButton :color="buttonColor" :style="{ width: '200px' }" @click="showAddChannelModal">
          <template #left>
            <IconPlusSm />
          </template>
          {{ buttonText }}
        </PButton>
      </div>
    </div>
    <ProfileChannelList />
  </div>
</template>

<style scoped>
.profile-channel {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 24px 24px 16px;
  border-radius: 16px;
  border: 1px solid var(--common-smog);
  gap: 24px;
}

.profile-channel__header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.profile-channel__header-content {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.profile-channel__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.profile-channel__description {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--common-dark-water);
}
</style>
