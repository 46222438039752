<script lang="ts" setup>
import { computed, onMounted, reactive, ref, watch } from 'vue';
import { useBreakpoints } from '@vueuse/core';
import { useRouter, useRoute } from 'vue-router';
import { formatIncompletePhoneNumber } from 'libphonenumber-js';
import {
  IconChatMd as ChatIcon,
  IconProfileMd as ProfileIcon,
  IconRefreshMd as RefreshIcon,
  IconMailMd as BroadcastIcon,
  IconTemplateMd as TemplateIcon,
  IconPeopleMd as ContactsIcon,
  IconEnterMd,
  IconInfoSm,
  IconHeartHandshakeSm,
  IconBotSm,
  PTag,
} from '@profeat/ui-kit';

import { useProfile } from '~/stores/profile.store';
import { useUser } from '~/stores/user.store';
import { useChannels } from '~/stores/channel.store';
import { useAuth } from '~/stores/auth.store';
import { useCompany } from '~/stores/company.store';
import { SubscriptionStatus } from '~/entities/subscriptions';
import { ChannelStatus } from '~/entities/channel';
import { useI18n } from 'vue-i18n';

const router = useRouter();
const route = useRoute();
const { isSmaller, collapse, isVisible } = useBreakpoints({ collapse: 1500, isVisible: 1024 });
const profileStore = useProfile();
const userStore = useUser();
const channelsStore = useChannels();
const { logout, getUserPhone } = useAuth();
const { t } = useI18n()

enum SidebarItemsList {
  'profile',
  'contacts',
  'templates',
  'broadcast',
  'trigger-broadcast',
  'chats',
  'chat-bots',
}

type SidebarItemsListStrings = keyof typeof SidebarItemsList;

const isCollapsed = ref(false);

const phoneNumber = computed(() => formatIncompletePhoneNumber(`+${userStore.user.phoneNumber}`));
const enabledChatBot = computed(() => userStore.connectedTariff?.hasChatBot && !profileStore.hasTariffExpired);
const isDisabled = computed(() => profileStore.noActiveChannel || profileStore.hasNotFullAccess);
const isNonActiveSubscription = computed(() => userStore.subscription?.status !== SubscriptionStatus.Active);
const isDisabledBroadcast = computed(() => {
  return channelsStore.channels.every((el) => el.status === ChannelStatus.init)
});

const hasProfileAlert = computed(() => {
  const alerts = [
    profileStore.hasGiftTariffExpired,
    profileStore.hasTariffExpired,
    profileStore.isTariffWillEnd,
    profileStore.isGiftLimitEnd,
    profileStore.isAutoRenewLimitEnd,
    profileStore.isBalanceLimitEnd,
  ];

  return alerts.some(Boolean);
});
const hideTemplates = computed(
  () =>
    isDisabled.value ||
    profileStore.hasExpiredSubscription ||
    channelsStore.hasErrorChannel ||
    isNonActiveSubscription.value,
);
const showProChatBotsBadge = computed(() => {
  return userStore.connectedTariff && !userStore.connectedTariff.hasChatBot;
});

const state = reactive({
  selected: 'profile',
  isOpened: false,
})

const SUPPORT_TG_URL = 'https://t.me/care_sendbot';
const { getCompanyId } = useCompany()
const supportTgLink = computed(() =>
  `${SUPPORT_TG_URL}?start=${getUserPhone}_${getCompanyId}`
);

const exit = async () => {
  await logout();
  await router.push('/login');
};

const onClickPro = async () => {
  await router.push('/tariffs');
};

onMounted(() => {
  isCollapsed.value = isSmaller('collapse');
  const currentItem: SidebarItemsListStrings = route.path.split('/')[1] as SidebarItemsListStrings

  if(currentItem !== undefined && SidebarItemsList[currentItem] !== undefined){
    state.selected = currentItem
  }
});

watch(collapse, () => {
  isCollapsed.value = isSmaller('collapse');
  state.isOpened = collapse.value
});

watch(isVisible, () => {
  isCollapsed.value = isSmaller('isVisible');
  state.isOpened = isVisible.value && collapse.value
});
watch(
  () => router.currentRoute.value,
  (value) => {
    const isBroadcast = value.name === 'broadcastSender';
    if (isBroadcast) state.selected = 'broadcast';
  },
);

const clickEvent = (e: any) => {
  if(e.value !== 'logout' || e.value !== 'link') {
    state.selected = e.value
  }
  if (e.value === 'logout') {
    exit()
  }
};

</script>

<template>
  <div  class="sidebar">
  <PSideBar
    v-model:selected="state.selected"
    v-model:opened="state.isOpened"
    :items="{
      menu: !userStore.user.isWzIntegration
      ? [
          {
            value: 'profile',
            icon: ProfileIcon,
            text: t('menu.profile'),
            disabled: profileStore.isFirstEntry,
            indicatorColor: hasProfileAlert && 'error',
            to: '/profile',
          },
          {
            value: 'contacts',
            icon: ContactsIcon,
            text: t('menu.customers'),
            to: '/contacts',
          },
          {
            value: 'templates',
            icon: TemplateIcon,
            text: t('menu.templatesWABA'),
            disabled: hideTemplates,
            to: '/templates',
          },
          'divider',
          {
            value: 'broadcast',
            icon: BroadcastIcon,
            text: t('menu.mass-mailing'),
            disabled: isDisabledBroadcast,
            to: '/broadcast',
          },
          {
            value: 'trigger-broadcast',
            icon: RefreshIcon,
            text: t('menu.trigger-broadcast'),
            disabled: isDisabled || isNonActiveSubscription,
            to: '/trigger-broadcast',
          },
          'divider',
          {
            value: 'chats',
            icon: ChatIcon,
            text: t('menu.chats'),
            disabled: isDisabled || isNonActiveSubscription,
            to: '/chats',
          },
          {
            value: 'chat-bots',
            description: showProChatBotsBadge ? 'PRO' : '',
            icon: IconBotSm,
            text: t('menu.chat-bots'),
            disabled: isDisabled || !enabledChatBot || isNonActiveSubscription,
            templateBy: 'tag',
            to: '/chat-bots',
          },
        ]
      : [
          {
            value: 'profile',
            icon: ProfileIcon,
            text: t('menu.profile'),
            disabled: profileStore.isFirstEntry,
            indicatorColor: hasProfileAlert && 'error',
            to: '/profile',
          },
          {
            value: 'contacts',
            icon: ContactsIcon,
            text: t('menu.customers'),
            to: '/contacts',
          },
          'divider',
          {
            value: 'broadcast',
            icon: BroadcastIcon,
            text: t('menu.mass-mailing'),
            disabled: isDisabledBroadcast,
            to: '/broadcast',
          },
          {
            value: 'trigger-broadcast',
            icon: RefreshIcon,
            text: t('menu.trigger-broadcast'),
            disabled: isDisabled || isNonActiveSubscription,
            to: '/trigger-broadcast',
          },
          'divider',
          {
            value: 'chat-bots',
            description: showProChatBotsBadge ? 'PRO' : '',
            icon: IconBotSm,
            text: t('menu.chat-bots'),
            disabled: isDisabled || !enabledChatBot || isNonActiveSubscription,
            templateBy: 'tag',
            to: '/chat-bots',
          },
        ]
      ,
      bottom: [
        {
          value: 'link',
          icon: IconHeartHandshakeSm,
          href: supportTgLink,
          text:  t('menu.help'),
          target: '_blank'
        },
        {
          value: 'link',
          icon: IconInfoSm,
          href: 'https://sendbot.yourgood.ru/help',
          text: t('menu.knowledge-db'),
          target: '_blank',

        },
        {
          value: 'logout',
          icon: IconEnterMd,
          text: t('auth.logout'),
        },
      ],
    }"
    :title="phoneNumber"
    :padding="'medium'"
    :product="'sendbot'"
    @click:item="clickEvent($event)"
  >
    <template #right-side-item="{ item }">
      <PTag v-if="item.description" color="blue" class="font-weight-regular text-14px">
        {{ item.description }}
      </PTag>
    </template>
  </PSideBar>
  </div>
</template>

<style lang="scss" scoped>
  .sidebar {
    border-right: 1px solid #2b323814;
    :deep(){
      .item-wrapper{
        align-items: baseline;
      }
    }
  }
</style>
