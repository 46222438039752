<script lang="ts" setup>
import { useVModel, useStorage } from "@vueuse/core";

// Icons
import IconChannelWaba from "~/assets/icons/profile-channel-waba.png";
import IconChannelTg from "~/assets/icons/profile-channel-tg.png";

const props = defineProps<{ modelValue: boolean }>();
const emits = defineEmits<{
  (event: "update:modelValue", payload: boolean): void;
  (event: "select", payload: "wz" | "tg"): void;
}>();

const visibleModel = useVModel(props, "modelValue", emits);
const onClose = () => {
  emits("update:modelValue", false);
};

const preRelease = useStorage('pre-release', false)

const onAddChannel = (type: "wz" | "tg") => {
  emits("select", type);
  onClose();
};
</script>

<template>
  <PDialog v-model="visibleModel" width="small">
    <template #header>
      <header>
        <p>{{ $t("profile.modals.addChannel.title") }}</p>
        <IconCrossMd class="icon" @click="onClose" />
      </header>
    </template>
    <div class="main">
      <p>{{ $t("profile.channel.selectWzDescription") }}</p>
      <ul>
        <li @click="onAddChannel('wz')">
          <div class="icon">
            <img :src="IconChannelWaba" alt="" width="32" height="32" />
          </div>
          <div class="content">
            <h4>{{ $t("profile.modals.addChannel.wabaTitle") }}</h4>
            <p>{{ $t("profile.modals.addChannel.wabaDescription") }}</p>
          </div>
        </li>
        <li v-if="preRelease" @click="onAddChannel('tg')">
          <div class="icon">
            <img :src="IconChannelTg" alt="" width="32" height="32" />
          </div>
          <div class="content">
            <h4>{{ $t("profile.modals.addChannel.tgTitle") }}</h4>
            <p>{{ $t("profile.modals.addChannel.tgDescription") }}</p>
          </div>
        </li>
      </ul>
    </div>
    <template #footer></template>
  </PDialog>
</template>

<style scoped>
header {
  display: flex;
  position: relative;
  box-sizing: border-box;
  padding: 12px 16px;
  box-shadow: 0px -1px 0px 0px rgba(0, 0, 0, 0.08) inset;
}

header p {
  width: 100%;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
}

header .icon {
  position: absolute;
  right: 16px;
  cursor: pointer;
}

.main {
  box-sizing: border-box;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  color: var(--common-asphalt);
}

.main > p {
  text-align: center;
}

.main > ul {
  list-style: none;
}

.main > ul > li {
  position: relative;
  display: flex;
  gap: 12px;
  align-items: flex-start;
  box-sizing: border-box;
  padding: 16px 0;
  cursor: pointer;
  transition: all 0.15s ease-in-out;
}

.main > ul > li:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.main > ul > li:hover {
  opacity: 64%;
}

.content > h4 {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: var(--common-black);
}

.content > p {
  font-size: 12px;
  line-height: 16px;
}

.content > h4 + p {
  margin-top: 4px;
}

.main > ul > li.is-soon:hover {
  opacity: 1;
}

.soon {
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding: 16px 0;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.soon > span {
  font-size: 14px;
  font-weight: 600;
  line-height: 20px;
  color: var(--common-rain);
}
</style>
