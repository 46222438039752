import type { Chatbot, ChatbotRequestBody } from "~/entities/chatbots";
import { ChatBotTestingDTO } from "~/stores/chatbots.store";
import { CustomersFilter } from '~/entities/customer';
import { BaseCrudApi } from "./abstract/base-crud.api";

export class ChatbotsApi extends BaseCrudApi {
  constructor() {
    super("chatbot");
  }

  async createChatBot(body: ChatbotRequestBody) {
    return this.api.post<Chatbot>("", body);
  }

  async getOneChatBotWithConnections(id: any) {
    return this.api.get<any>(id, { params: { tree: true } });
  }

  async getStatistics(chatBotId: string) {
    return this.api
      .get("statistics", { params: { chatBotId } })
      .then(({ data }) => data);
  }

  async getChatBotsTemplate() {
    return this.api
      .post<{
        [templateId: string]: { chatBotId: string; chatBotName: string }[];
      }>("get-template-map")
      .then(({ data }) => data);
  }

  async activateTemplateBot(data: { chatBotId: string }) {
    return this.api
      .post<string>("activate-template", data)
      .then(({ data }) => data);
  }

  async testing(payload: ChatBotTestingDTO) {
    return this.api.post("/testing", payload);
  }

  async enable(payload: ChatBotTestingDTO) {
    return this.api.post("/enable", payload);
  }

  async fetchTemplateStartType(chatBotId: string) {
    return this.api.get(`${chatBotId}/type`);
  }

  async isOtherActive(chatBotId: string): Promise<Chatbot> {
    return this.api.get(`${chatBotId}/is-active`);
  }

  async getActiveCount(filters: CustomersFilter[]): Promise<{ count: number }> {
    return this.api.post('/customer-count', { filters }).then(({ data }) => data);
  }

  async sendTestingBroadcast(chatBotId: string, phone: string) {
    return this.api.post("/test-send", { chatBotId, phone });
  }

  async disable(id: string) {
    return this.api.post<{ chatBotId: string }>("disable", { chatBotId: id });
  }

  async disableTesting(id: string) {
    return this.api.post<{ chatBotId: string }>("disable-testing", {
      chatBotId: id,
    });
  }

  async validateSteps(chatBotId: string) {
    return this.api
      .post(`/${chatBotId}/validate-steps`, {})
      .then(({ data }) => data);
  }

  async addFile(stepId: string, file: any, id: string) {
    return this.api.post(
      `/upload-file/${id}?_nocache=${new Date().getTime()}`,
      {
        stepId: stepId,
        headerFile: file,
      },
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
  }
}
