<script setup lang="ts">
import { storeToRefs } from 'pinia';
import { computed } from 'vue';
import { PTooltip } from '@profeat/ui-kit';

import IconChannelWaba from '~/assets/icons/profile-channel-waba.png';
import IconChannelTg from '~/assets/icons/profile-channel-tg.png';
import { Channel, ChannelStatus, ChannelsTransportType, MessageLimitTier } from '~/entities/channel';
import { useProfile } from '~/stores/profile.store';
import { useUser } from '~/stores/user.store';

const URL_HOME = 'https://business.facebook.com/wa/manage/home';
const URL_CONFIRM = 'https://business.facebook.com/settings/security';

const userStore = useUser();
const profileStore = useProfile();

const props = defineProps<{ channel: Channel; divider: boolean }>();

const emits = defineEmits<{
  (event: 'delete', channelId: string): void;
  (event: 'edit', channelId: string): void;
}>();

const channelIcon = computed(() => props.channel.transport === ChannelsTransportType.telegram ? IconChannelTg : IconChannelWaba);

// Срок действия тарифа истек
// Красный статус "Тариф не оплачен"
const isTariffExpired = computed(() => profileStore.hasTariffExpired || profileStore.hasGiftTariffExpired);

// Канал находится на модерации
// Синий статус "На модерации"
const isOnModeration = computed(
  () => props.channel.status === ChannelStatus.onModeration && props.channel.verificationLink,
);

// Канал является новым, нужно подтверждение бизнеса
// Зеленый статус "Новый"
const isNew = computed(
  () => props.channel.status === ChannelStatus.active && props.channel.tier === MessageLimitTier.TIER_0,
);

// Канал активный
// Зеленый статус "Активен"
const isActive = computed(
  () => props.channel.status === ChannelStatus.active && props.channel.tier !== MessageLimitTier.TIER_0,
);

// Флаг для отображения кнопок
// Кнопки не отображаются для интегрированных юзеров
const { isWzIntegration } = storeToRefs(userStore);

const onDelete = () => {
  emits('delete', props.channel.id);
};

const onEdit = () => {
  emits('edit', props.channel.id);
};
</script>

<template>
  <div class="profile-channel__list-item">
    <div class="profile-channel__list-item-info">
      <div class="profile-channel__list-item-icon">
        <img :src="channelIcon" width="32" height="32" alt="" />
      </div>
      <div class="profile-channel__list-item-name">{{ channel.title }}</div>
      <div class="profile-channel__list-item-status">
        <PTag v-if="isTariffExpired" :color="'pink'" text="Тариф не оплачен" />
        <PTag v-else-if="isActive" :color="'green'" text="Активен" />
        <PTooltip v-else-if="isOnModeration">
          <template #activator="{ setReference, click }">
            <span
              :ref="setReference"
              @click="click"
            >
              <PTag :color="'blue'" text="На модерации">
                <template #right>
                  <icon-info-sm />
                </template>
              </PTag>
            </span>
          </template>

          <template #text>
             <div class="profile-channel__list-item-popover">
              <p>{{ $t('profile.channel.tooltip.moderation.paragraph1') }}</p>
              <br />
              <p>
                {{ $t('profile.channel.tooltip.moderation.paragraph2') }}
                <a :href="props.channel.verificationLink" target="_blank">{{
                  $t('profile.channel.tooltip.moderation.link1')
                }}</a>
              </p>
              <br />
              <p>
                {{ $t('profile.channel.tooltip.moderation.paragraph3_1') }}
                <a :href="URL_HOME" target="_blank">{{ $t('profile.channel.tooltip.moderation.link2') }}</a>
                {{ $t('profile.channel.tooltip.moderation.paragraph3_2') }}
              </p>
            </div>
          </template>

        </PTooltip>
        <PTooltip v-else-if="isNew">
          <template #activator="{ setReference, click }">
            <span
                :ref="setReference"
                @click="click"
              >
              <PTag :color="'green'" text="Новый">
                <template #right>
                  <icon-info-sm />
                </template>
              </PTag>
            </span>
          </template>
          <template #text>
            <div class="profile-channel__list-item-popover">
              <p>{{ $t('profile.channel.tooltip.tierZero.paragraph1') }}</p>
              <br />
              <p>
                {{ $t('profile.channel.tooltip.tierZero.paragraph2') }}
                <a :href="URL_CONFIRM" target="_blank">{{ $t('profile.channel.tooltip.tierZero.link') }}</a>
              </p>
            </div>
          </template>
        </PTooltip>
      </div>
    </div>
    <div class="profile-channel__list-item-btn">
      <PButton
        v-if="isOnModeration"
        :href="channel.verificationLink"
        :size="'x-small'"
        :color="'secondary'"
        :style="{ width: '142px' }"
        target="_blank"
      >
        {{ $t('profile.channel.continueVerification') }}
      </PButton>
      <PButton v-if="isNew" :href="URL_CONFIRM" :size="'x-small'" :color="'secondary'" :style="{ width: '142px' }">
        {{ $t('profile.channel.confirmBusiness') }}
      </PButton>
      <template v-if="!isWzIntegration">
        <PButton :variant="'text'" :color="'secondary'" @click="onEdit">
          <IconFilledEditMd :style="{ color: 'var(--common-asphalt)' }" />
        </PButton>
        <PButton :variant="'text'" :color="'secondary'" @click="onDelete">
          <IconTrashMd :style="{ color: 'var(--common-asphalt)' }" />
        </PButton>
      </template>
    </div>
  </div>
  <div v-if="divider" class="profile-channel__list-divider"></div>
</template>

<style scoped>
.profile-channel__list-item {
  box-sizing: border-box;
  padding: 12px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 12px;
}
.profile-channel__list-item-btn {
  display: flex;
  gap: 16px;
}
.profile-channel__list-item-info {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-basis: calc(100% * 0.4);
  gap: 12px;
}
.profile-channel__list-item-name {
  flex-grow: 1;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
}

.profile-channel__list-item-popover {
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
}

.profile-channel__list-item-popover a {
  color: var(--el-color-primary);
}

.profile-channel__list-divider {
  background-color: rgba(0, 0, 0, 0.08);
  display: block;
  height: 1px;
  width: 100%;
  margin: 8px 0;
}
</style>
