import { useToast } from "@profeat/ui-kit";
import { defineStore } from "pinia";
import type { Graph, IEditorState, IGraphState, NodeInterface } from "baklavajs";

import { api } from "~/api";
import {
  Bot,
  BotRequestBody,
  BotStatus,
  BotTypes,
  Chatbot,
  ChatbotRequestBody,
  ChatbotsStatus,
  ChatbotStep,
  ChatBotStepType,
} from "~/entities/chatbots";
import { useTemplates } from "~/stores/template.store";
import { useUser } from "~/stores/user.store";
import { useChatbotsWs } from "~/stores/chatbots-ws.store";
import { TemplateCategoryType } from "~/entities/template";
import { $t } from "~/i18n";
import { v4 as uuidv4 } from "uuid";
import { isUrl } from "~/utils/string-helper";
import { useContacts } from '~/stores/contacts.store';
import { ChannelsTransportType } from '~/entities/channel';

export type ChatBotStartType = "incoming" | "outgoing" | "telegram";

export type ConstructorType= "waba" | "telegram";

type ChatBotType = Chatbot & {
  start: ChatBotStartType | null;
  templateText: string | null;
};
type Chatbots = Array<ChatBotType>;

type BotType = Bot & {
  start: ChatBotStartType | null;
  templateText: string | null;
};

export interface ChatBotTestingDTO {
  chatBotId: string;
  phone?: string;
  schema?: string
}

interface IConnection {
  stepFromId: string;
  stepToId: string;
  outputKey: string;
  // ID невидимого-теневого STEP'а который связывает между собой два шага
  shadowStepId: string;
}

interface ISchema {
  steps: ChatbotStep[];
  connections: IConnection[];
}

interface State {
  chatbots: Chatbots;
  steps: ChatbotStep[];
  templateChatBots: {
    [templateId: string]: { chatBotId: string; chatBotName: string }[];
  };
  workingDays: string[];
  isFetching: boolean;
  currentChatbot: ChatBotType;
  notClosedConnections: string[];
  isIntegrity: boolean;
  showTestModal: boolean;
  isLoadingFlagForTestModal: boolean;
  templateStartType: boolean;
  stats: boolean;
  templateButtonVariables: Record<string, Record<string, string>>;
  showActivateModal: boolean;
  otherActive: Chatbot | null;
  hasStats: boolean;
  stepStatistics: {
    [stepId: string]: { statistics: any; outputStatistics: any };
  };
  schema: ISchema | undefined;
  connections: IConnection[] | undefined;
  status: ChatbotsStatus;
  botsData: Bot[];
  botData: Bot;
  botSchema: Graph | any;
  isActivateInstantly: boolean;
  alertNodeIds:  Set<string>;
  constructorType: ConstructorType;
}

export const useChatbots = defineStore("chatbots", {
  state: (): State => ({
    chatbots: [],
    steps: [],
    templateChatBots: {},
    workingDays: [],
    isFetching: false,
    currentChatbot: {} as ChatBotType,
    notClosedConnections: [],
    isIntegrity: true,
    showTestModal: false,
    isLoadingFlagForTestModal: true,
    templateStartType: false,
    stats: false,
    templateButtonVariables: {},
    showActivateModal: false,
    otherActive: null,
    hasStats: false,
    stepStatistics: {},
    schema: undefined,
    connections: undefined,
    status: ChatbotsStatus.draft,
    botsData: [] as Bot[],
    botData: {} as Bot,
    botSchema: {} as Graph,
    isActivateInstantly: false,
    alertNodeIds: new Set,
    constructorType: 'telegram',
  }),

  actions: {
    setTemplateStartType(val: boolean) {
      this.templateStartType = val;
    },
    setStats(value: boolean) {
      this.stats = value;
    },
    setBotsData(value: Bot[]) {
      this.botsData = value;
    },
    setBotData(value: Bot) {
      this.botData = value;
    },
    setHasStats(payload: boolean) {
      this.hasStats = payload;
    },
    setErrorMessage(payload: boolean){

    },
    setStatus(payload: ChatbotsStatus) {
      this.status = payload;
    },
    setAlertNodeIds(payload:  Set<string>){
      this.alertNodeIds = payload
    },
    setConstructorType(payload: ConstructorType){
      this.constructorType = payload
    },

    async createBot(body: BotRequestBody) {
      const { data } = await api.bot.createBot(body);
      return data as Bot;
    },

    async getBots(skip: string, take: string) {
      const templateStore = useTemplates();
      
      await templateStore.fetch();
      const { data } = await api.bot.getBots(take, skip);
      this.botsData = (data as Bot[])
      .map((bot) => {
        if(bot.type === 'TELEGRAM'){
          return {
            ...bot,
            startType: 'telegram' as ChatBotStartType
          }
        }
        const cuurentBotSnap: IGraphState = bot.botSnapshots.find(snapshot => snapshot.isCurrent)?.schema as IGraphState
        if(!(cuurentBotSnap?.nodes?.[0]?.type === "WabaStartNode")){
          return {
            ...bot,
            templateText: null,
            startType: null
          }
        }
        const valuea: string = cuurentBotSnap?.nodes?.[0].outputs?.tabs?.value as unknown as string
        if(valuea === 'incoming') {
          return {
            ...bot,
            templateText: null,
            startType: 'incoming' as ChatBotStartType
          }
        } else {
          const templateStore = useTemplates();
          const templateText = templateStore.getTemplatesMap[cuurentBotSnap.nodes[0].outputs?.selectTemplate.value as unknown as string]?.title ||
            null;
          return {
            ...bot,
            startType: 'outgoing' as ChatBotStartType,
            templateText: templateText,
          }
        } 
        
      })
      .sort((a, b) => {
        if (a.status === 'ACTIVE') return -1;
        if (b.status === 'ACTIVE') return 1;

        return +new Date(b.createdAt) - +new Date(a.createdAt);
      });
    },

    async getOneBot(id: string) {
      const { data } = await api.bot.getOneBot(id);
      this.botData = data as Bot
      this.setStatus(data.status.toLowerCase() as ChatbotsStatus)
      if (data.botSnapshots){
        this.botSchema = data.botSnapshots[0].schema as Graph
      }

      return data as Bot;
    },

    async updateBot(id: string, body: BotRequestBody) {
      const userStore = useUser();
      const { data } = await api.bot.updateBot(id, body, userStore.user.id); 
      return data as Bot;
    },

    async putchBot(id: string, body: {
      status?: string,
      name?: string,
      channelId?: string,
    }) {
      const { data } = await api.bot.putchBot(id, body);

      return data as Bot;
    },

    async deleteBot(id: string) {
      const { data } = await api.bot.deleteBot(id);
      return data as Bot;
    },

    setOtherActive(payload: Chatbot | null) {
      this.otherActive = payload;
    },

    async createNewChatBot(body: ChatbotRequestBody) {
      const { data } = await api.chatbots.createChatBot(body);
      return data as Chatbot;
    },

    async updateWorkingDays(days: string[]) {
      this.workingDays = days;
    },

    async fetchBotsByTemplate() {
      await this.getBots('0', '100');
      const templateChatBots:{
        [templateId: string]: { chatBotId: string; chatBotName: string }[];
      } = {}
      this.botsData
      .filter(bot => 
        bot.type === BotTypes.waba && 
        (bot.status.toLowerCase() === ChatbotsStatus.ready || bot.status.toLowerCase() === ChatbotsStatus.active) && 
        bot.startType === 'outgoing' as ChatBotStartType
      )
      .forEach((bot) => {
        const currentSchema: IGraphState = bot.botSnapshots.find(botSnapshot => botSnapshot.isCurrent)?.schema as IGraphState
        const templateId: string = currentSchema.nodes[0].outputs.selectTemplate.value as unknown as string
        if(templateId !== ''){
          if(templateChatBots[templateId]){
            templateChatBots[templateId].push({
              chatBotId: bot.id,
              chatBotName: bot.name
            })
          } else {
            templateChatBots[templateId] = []
            templateChatBots[templateId].push({
              chatBotId: bot.id,
              chatBotName: bot.name
            })
          }
        }
        
      })
      this.templateChatBots = templateChatBots
    },

    async sendTestBroadcast(phoneNumber: string) {
      const { showToast } = useToast();
      try {
        await api.chatbots.sendTestingBroadcast(
          this.getCurrent.id,
          phoneNumber.replace("+", "")
        );
        showToast({
          text: "Рассылка отправлена успешно",
          color: "success",
        });
      } catch (err) {
        showToast({
          text: "Не получилось отправить рассылку",
          color: "error",
        });
      }
    },

    async deleteTgBot(id: string) {
      const { showToast } = useToast();
      await this.deleteBot(id)
      await this.getBots('0', '100')

      showToast({
        text: $t("chatbots.chatBotRemoved"),
        color: "default",
      });
    },

    setFetching(payload: boolean) {
      this.isFetching = payload;
    },

    async disableActiveBot(id: string) {
      await api.bot.putchBot(id, {status: BotStatus.Draft})

      const updateIndex = this.botsData.findIndex(
        ({ id: chatbotId }) => chatbotId === id
      );

      if (updateIndex !== -1) {
        const updated = {
          ...this.botsData[updateIndex],
          status: ChatbotsStatus.draft,
        };
        const newBots = this.botsData.splice(updateIndex, 1, updated);
        this.setBotsData(newBots)
      }
    },

    async fetchStepStatistics() {
      if (!this.currentChatbot.id) return;
      this.stepStatistics = await api.chatbots.getStatistics(
        this.currentChatbot.id
      );
    },

    setCurrent(payload: ChatBotType) {
      this.currentChatbot = payload;
    },

    setShowTestModal(payload: boolean) {
      this.showTestModal = payload;
    },

    setIsLoadingFlagForTestModal(flag: boolean) {
      this.isLoadingFlagForTestModal = flag;
    },

    setIsIntegrity(flag: boolean) {
      this.isIntegrity = flag;
    },



    detectTypeStartStep(payload: ChatbotStep): {
      start: ChatBotStartType | null;
      templateText: string | null;
    } {
      const templateStore = useTemplates();
      const result: {
        start: ChatBotStartType | null;
        templateText: string | null;
      } = {
        start: null,
        templateText: null,
      };

      if (payload.type === ChatBotStepType.start) {
        if (Object.values(payload.function.send).length) {
          result.start = "outgoing";
          result.templateText =
            templateStore.getTemplatesMap[payload.function.send?.text]?.title ||
            null;
        } else {
          result.start = "incoming";
        }
      }

      return result;
    },

    async disableTestingBot() {
      const id = this.botData?.id;
      
      if (id) {
        await api.bot.putchBot(id, {status: BotStatus.Draft})
        const newBot = this.botData
        newBot.status = BotStatus.Draft
        this.setBotData(newBot)
      }
    },
  },

  getters: {
    getStats: (state: State) => state.stats,
    getChatbots: (state: State) => state.botsData,
    getWorkingDays: (state: State) => state.workingDays,
    getActiveChatbots: (state: State) =>
      state.botsData.filter((item) =>
        [ChatbotsStatus.active, ChatbotsStatus.ready].includes(item.status as ChatbotsStatus)
      ),
    getDisabledChatbots: (state: State) =>
      state.botsData.filter((item) =>
        [ChatbotsStatus.draft].includes(item.status as ChatbotsStatus)
      ),
    getTemplateChatbots: (state: State) => state.templateChatBots,
    getFetching: (state: State) => state.isFetching,
    getCurrent: (state: State) => state.botData,
    hasChatbotDisabled: () => {
      const userStore = useUser();
      const tariff = userStore.connectedTariff;

      return !tariff || !tariff.hasChatBot;
    },
    isTemplateStart: (state: State) => state.templateStartType,
    showModal: (state: State) => state.showTestModal,
    getIsLoadingFlagForTestModal: (state: State) =>
      state.isLoadingFlagForTestModal,
    getIsIntegrity: (state: State) => state.isIntegrity,
    getIncomingActiveBots: (state: State) => {
      return state.botsData.filter(({ startType, channel, status }) => {
        return (
          startType === 'incoming' &&
          channel!.transport === ChannelsTransportType.wapi &&
          status === ChatbotsStatus.active
        );
      });
    },
  },
});
