import { NavigationGuardNext, RouteLocationNormalized } from "vue-router";

export const profileGuard = (
  to: RouteLocationNormalized,
  from: RouteLocationNormalized,
  next: NavigationGuardNext
) => {
  if (
    ["currentChannel", "channelCreation", "integrationUser"].includes(
      <string>from.name
    ) &&
    to.name === "profile"
  ) {
    return { name: "none" };
  }
  next();
};
