<script setup lang="ts">
import { useI18n } from 'vue-i18n';
import { useRouter } from 'vue-router';
import { computed, ref, onMounted } from 'vue';
import dayjs from 'dayjs';

import ProfileModalConfirm from '~/components/globalComponents/profileV2/modal/ProfileModalConfirm.vue';
import { useUser } from '~/stores/user.store';
import { SubscriptionStatus } from '~/entities/subscriptions';
import { usePayment } from '~/stores/payment.store';
import { useTariffs } from '~/stores/tariffs.store';
import { getSeparatorNumber } from '~/utils/string-helper';
import { useChannels } from '~/stores/channel.store';

const modalConfirmation = ref<InstanceType<typeof ProfileModalConfirm>>();

const subscriptionStatusContent: {
  [key: string]: { localize: string };
} = {
  [SubscriptionStatus.Pending]: {
    localize: 'tariffs.tariffPending',
  },
  [SubscriptionStatus.Closed]: {
    localize: 'tariffs.tariffExpired',
  },
  [SubscriptionStatus.WaitingForChannel]: {
    localize: 'tariffs.willBeActivated',
  },
};

const { t } = useI18n();
const router = useRouter();
const userStore = useUser();
const channelsStore = useChannels();
const tariffsStore = useTariffs();
const { setDialogVisible, setPaymentMode } = usePayment();

const redirectToTariffs = async () => {
  await router.push({ name: 'tariffs' });
};
const onExtendClick = () => {
  setPaymentMode('renew');
  setDialogVisible(true);
};

const isActiveSubscription = computed(() => userStore.subscription?.status === SubscriptionStatus.Active);
const isNonGift = computed(() => !userStore.connectedTariff?.isGift);
const getDate = computed(() => {
  const currentDate = userStore.subscriptionActiveTo;

  return dayjs(currentDate).format('DD.MM.YYYY hh:mm');
});
const getNonActiveInfo = computed(() => {
  const { status } = userStore.subscription!;

  return t(subscriptionStatusContent[status].localize);
});
const visibleExtendBtn = computed(() => {
  const isNonAutoRenew = !userStore.chainAutoRenew;
  const isTariffActive = userStore.connectedTariff?.isActive;

  return isNonGift.value && isNonAutoRenew && isActiveSubscription.value && isTariffActive;
});

const tariffCost = computed(() => {
  const cost = getSeparatorNumber(userStore.connectedTariff!.cost);
  return `${cost} ${t('tariffs.cost per month')}`;
});

const onDisableAutoRenew = async () => {
  const ok = await modalConfirmation.value!.show({
    title: 'Автопродление',
    message: 'Сейчас оно включено, и можно ни о чём не волноваться. Тариф будет продлеваться автоматически.',
    okBtn: 'Супер!',
    cancelBtn: 'Отключить',
    reverseBtn: true,
  });
  if (ok) {
    const chainId = userStore.subscription?.chainId;
    await tariffsStore.updateRenewal({
      isRenew: false,
      chainId: chainId!,
    });
    userStore.setUser({ ...userStore.user, autoRenew: false });
  } else {
  }
};

onMounted(() => {
  tariffsStore.setTariff(userStore.connectedTariff);
});
</script>
<template>
  <ProfileModalConfirm ref="modalConfirmation" />
  <div class="profile-tariff">
    <h4 class="profile-tariff__title">{{ $t('profile.tariff.title') }}</h4>
    <div class="profile-tariff__footer">
      <div class="profile-tariff__info">
        <span class="profile-tariff__name"
          >{{ userStore.connectedTariff?.title?.toUpperCase() }}
          {{ !userStore.connectedTariff?.isGift ? tariffCost : '' }}</span
        >
        <div class="profile-tariff__info-bottom">
          <span v-if="isActiveSubscription" class="profile-tariff__date"
            >{{ $t('tariffs.expired') }} {{ getDate }}</span
          >
          <span v-else class="profile-tariff__date">{{ getNonActiveInfo }}</span>
          <div
            class="profile-tariff__hint"
            v-if="userStore.chainAutoRenew && userStore.connectedTariff?.isActive"
            @click="onDisableAutoRenew"
          >
            <PHint :placement="'right'">
              <template #activator="{ setReference, onHover, outHover }">
                <IconRefreshMd
                  style="color: var(--color-primary); cursor: pointer"
                  :ref="setReference"
                  @mouseover="onHover"
                  @mouseleave="outHover"
                />
              </template>
              <template #hint> {{ $t('profile.modals.autoRenewal.title') }} </template>
            </PHint>
          </div>
        </div>
      </div>
      <div class="profile-tariff__btn">
        <PButton v-if="visibleExtendBtn" :style="{ width: '154px' }" @click="onExtendClick">{{
          $t('profile.modals.autoRenewal.renewBtn')
        }}</PButton>
        <PButton
          :color="visibleExtendBtn || !channelsStore.channels.length ? 'secondary' : 'primary'"
          :style="{ width: '154px' }"
          @click="redirectToTariffs"
          >{{ $t('profile.modals.autoRenewal.selectAnotherBtn') }}</PButton
        >
      </div>
    </div>
  </div>
</template>

<style scoped>
.profile-tariff {
  display: flex;
  flex-direction: column;
  gap: 24px;
  box-sizing: border-box;
  padding: 23px 24px;
  border-radius: 16px;
  border: 1px solid var(--common-smog);
}

.profile-tariff__title {
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  letter-spacing: 0em;
}

.profile-tariff__footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.profile-tariff__info {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.profile-tariff__name {
  font-size: 22px;
  font-weight: 600;
  line-height: 32px;
  letter-spacing: -0.01em;
}

.profile-tariff__info-bottom {
  display: flex;
  gap: 16px;
  align-items: center;
  height: 24px;
}

.profile-tariff__date {
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  color: var(--common-asphalt);
}

.profile-tariff__hint {
  height: 20px;
}

.profile-tariff__btn {
  display: flex;
  gap: 16px;
}
</style>
